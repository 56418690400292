/*
 * Around | Multipurpose Bootstrap Template
 * Copyright 2021 Createx Studio
 * Theme styles
 */

// User variables
@import 'user-variables';

// Configuration & utilities
@import 'variables';
@import 'mixins';
@import 'utilities';

// Bootstrap
@import 'node_modules/bootstrap/scss/bootstrap';

// Layout & components
@import 'reboot';
@import 'components';

// User custom styles
@import 'user';
