// 
// user-variables.scss
// Use this to override Bootstrap and Cartzilla variables
//

// Example of a variable override to change Cartzilla background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;
$gray-700:            	#5a5b75;
$primary:               #132b4c;
$danger:               #c7203b;

$navbar-light-color: $primary;
$navbar-light-hover-color: $danger;

