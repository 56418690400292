//
// user.scss
// Use this to write your custom SCSS
//

/**
 * Enable smooth scrolling on the whole document
 */
html {
	scroll-behavior: smooth;
}

/**
 * Disable smooth scrolling when users have prefers-reduced-motion enabled
 */
 @media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

.swapcolor-image {
    filter: grayscale(0.8) opacity(0.5) contrast(0.5) brightness(1.15) saturate(1.5);
    transition: filter 0.5s ease-in-out;

    &:hover {
        filter: none;
    }
}

.intro-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../img/demo/marketing-seo/hero/office2.jpg");
  min-height: 300px;
  background-position: center;

  @include media-breakpoint-up(md){
    min-height: 570px;
  }
  @include media-breakpoint-up(lg){
    min-height: 650px;
  }
}

.text-justify {
  text-align: justify;
}
